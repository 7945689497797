import {gql} from '@apollo/client';

export const CREATE_ADDRESS = gql`
    mutation CreateAddress($object: delivery_address_insert_input = {}) {
        insert_delivery_address_one(object: $object) {
            id
        }
    }
`

export const CREATE_ORDER = gql`
    mutation CreateOrder($object: order_insert_input = {}) {
        insert_order_one(object: $object) {
            id
        }
    }
`

export const ATTACH_PICTURE = gql`
    mutation AttachPicture($order_id: uuid!, $src: String = "") {
        insert_pictures_one(object: {order_id: $order_id, src: $src}) {
            id
            order_id
            src
        }
    }
`

export const HIDE_ORDER = gql`
    mutation HideOrder($carrier_id: uuid = "", $order_id: uuid = "") {
        insert_carrier_hidden_orders_one(object: {carrier_id: $carrier_id, order_id: $order_id}, on_conflict: {constraint: carrier_hidden_orders_pkey, update_columns: carrier_id}) {
            carrier_id
            order_id
        }
    }
`

export const GET_SERVICE_PROVIDERS = gql`
    query GetServiceProviders {
        service_provider {
            value
        }
    }
`

export const SUBSCRIBE_ORDER_BY_ID_WITH_BID =  gql`
subscription SubscribeOrderWithBid($orderId: uuid!, $bidId: uuid!) {
  order_by_pk(id: $orderId) {
    id
    description
    insurance_price
    insurance_rate
    number
    price
    status
    wanted_delivery_date_from
    wanted_delivery_date_to
    wanted_pickup_date_from
    wanted_pickup_date_to
    order_sender {
      phone
      name
      email
      company_phone
      company_name
      comment
    }
    order_receiver {
      name
      phone
      email
      company_phone
      company_name
      comment
    }
    bids(where: {id: {_eq: $bidId}}) {
      id
      price
      pickup_date_to
      pickup_date_from
      delivery_date_to
      delivery_date_from
      bid_carrier {
        carrier_user_profile {
          first_name
          last_name
        }
      }
    }
    order_cargos {
      description
      quantity
      width
      weight
      length
      height
      is_packed
      is_insured
      insured_price
      price
    }
    from {
      address
      building_no
      city
      country
      door_no
      floor
      full_address
      is_elevator_available
      is_extraction_assisted
      is_extraction_required
      zip_code
    }
    to {
      address
      building_no
      city
      country
      door_no
      floor
      full_address
      is_elevator_available
      is_extraction_assisted
      is_extraction_required
      zip_code
    }
    order_price_updates {
      amount
      comment
      type
    }
    payments(where: {status: {_eq: PAID}}) {
      status
      amount
      payment_invoice {
        number
        id
        order_id
      }
    }
  }
}
`;

/**
 * @type {typeof import('gql/graphql').SubscribeOrderByIdDocument}
 */
export const SUBSCRIBE_ORDER_BY_ID = gql`
subscription SubscribeOrderById($id: uuid!) {
  order_by_pk(id: $id) {
    assigned_bid_id
    assigned_carrier_id
    confirmed_at
    shipment_id
    collected_at
    completed_at
    client_id
    created_at
    currency
    id
    from_delivery_address_id
    insurance_rate
    number
    order_bid {
      price
    }
    order_shipment {
      service_provider
      id
      price
      tracking_url
    }
    order_cargos {
      category_id
      description
      height
      id
      insured_price
      is_insured
      is_packed
      length
      order_id
      price
      quantity
      weight
      width
    }
    price
    payment_price
    insurance_price
    status
    to_delivery_address_id
    updated_at
    wanted_delivery_date_from
    wanted_delivery_date_to
    wanted_pickup_date_from
    wanted_pickup_date_to
    description
    pictures {
      src
      id
    }
    order_receiver {
      comment
      company_name
      company_phone
      id
      name
      phone
    }
    order_sender {
      comment
      company_name
      company_phone
      id
      name
      phone
    }
    client {
      discount
      feedbacks_aggregate {
        aggregate {
          count
          avg {
            rating
          }
        }
      }
      client_user_profile {
        user_profile_client {
          user_id
        }
        user_id
        address
        last_name
        first_name
        email
      }
    }
    from {
      address
      building_no
      city
      country
      created_at
      door_no
      floor
      full_address
      id
      is_elevator_available
      is_extraction_assisted
      is_extraction_required
      zip_code
    }
    to {
      address
      building_no
      city
      country
      created_at
      door_no
      floor
      full_address
      id
      is_elevator_available
      is_extraction_assisted
      is_extraction_required
      zip_code
    }
    order_carrier {
      user_id
    }
    payments(where: {status: {_eq: PAID}}) {
      status
      amount
      payment_invoice {
        number
        id
        order_id
      }
    }
  }
}
`;

/**
 * @type {typeof import('gql/graphql').GetOrderByIdDocument}
 */
export const GET_ORDER_BY_ID = gql`
    query GetOrderById($id: uuid!) {
        order_by_pk(id: $id) {
            assigned_bid_id
            assigned_carrier_id
            confirmed_at
            shipment_id
            collected_at
            completed_at
            client_id
            created_at
            currency
            id
            from_delivery_address_id
            insurance_rate
            number
            order_shipment {
                service_provider
                id
                price
                tracking_url
            }
            order_cargos {
                category_id
                description
                height
                id
                insured_price
                is_insured
                is_packed
                length
                order_id
                price
                quantity
                weight
                width
            }
            price
            payment_price
            insurance_price
            status
            to_delivery_address_id
            updated_at
            wanted_delivery_date_from
            wanted_delivery_date_to
            wanted_pickup_date_from
            wanted_pickup_date_to
            description
            pictures {
                src
                id
            }
            order_receiver {
                comment
                company_name
                company_phone
                id
                name
                phone
            }
            order_sender {
                comment
                company_name
                company_phone
                id
                name
                phone
            }
            client {
                feedbacks_aggregate {
                    aggregate {
                        count
                        avg {
                            rating
                        }
                    }
                }
                client_user_profile {
                    user_profile_client {
                        user_id
                    }
                    user_id
                    address
                    last_name
                    first_name
                    email
                }
            }
            from {
                address
                building_no
                city
                country
                created_at
                door_no
                floor
                full_address
                id
                is_elevator_available
                is_extraction_assisted
                is_extraction_required
                zip_code
            }
            to {
                address
                building_no
                city
                country
                created_at
                door_no
                floor
                full_address
                id
                is_elevator_available
                is_extraction_assisted
                is_extraction_required
                zip_code
            }
            order_carrier {
                user_id
            }
            order_bid {
              id
              comment
              created_at
              delivery_date_from
              delivery_date_to
              pickup_date_from
              pickup_date_to
              price
              status
              bid_carrier {
                carrier_user_profile {
                  first_name
                  last_name
                }
              }
            }
            payments(where: {status: {_eq: PAID}}) {
                status
                amount
                payment_invoice {
                    number
                    id
                    order_id
                }
            }
        }
    }
`
export const SMALL_DETAIL_ORDER = gql`
    query SmallOrderDetail($id: uuid!) {
        order_by_pk(id: $id) {
            client_id
            created_at
            currency
            id
            number
            price
            status
            updated_at
            order_bid {
                bid_carrier {
                    user_id
                }
            }
        }
    }
`

export const GET_LIST_HIDDEN_ORDERS = gql`
    query GetListHiddenOrders($carrierId: uuid!) {
        carrier_hidden_orders(where: {carrier_id: {_eq: $carrierId}}) {
            carrier_id
            order_id
        }
        working_area(where: {carrier_id: {_eq: $carrierId}}) {
            address
            city
            carrier_id
            country
            district
            work_outside_city
            id
        }
    }

`
export const CONFIRM_ORDER = gql`
    mutation ConfirmOrder($id: uuid = "", $assigned_bid_id: uuid = "", $assigned_carrier_id: uuid = "", $confirmed_at: timestamptz = "", $price: numeric = "") {
        update_order_by_pk(pk_columns: {id: $id}, _set: {assigned_bid_id: $assigned_bid_id, assigned_carrier_id: $assigned_carrier_id, status: CONFIRMED, confirmed_at: $confirmed_at, price: $price}) {
            id
            status
        }
    }
`

export const CONFIRM_PICKUP = gql`
    mutation ConfirmPickedUpStatus($id: uuid = "", $collected_at: timestamptz = "") {
        update_order_by_pk(pk_columns: {id: $id}, _set: {status: PICKED_UP, collected_at: $collected_at}) {
            id
            status
        }
    }
`

export const CONFIRM_DELIVERED = gql`
    mutation ConfirmCompletedStatusDelivered($id: uuid = "", $completed_at: timestamptz = "") {
        update_order_by_pk(pk_columns: {id: $id}, _set: {status: DELIVERED, completed_at: $completed_at}) {
            id
            status
        }
    }
`

export const CONFIRM_COMPLETED = gql`
    mutation ConfirmCompletedStatusComplseted($id: uuid!) {
        update_order_by_pk(pk_columns: {id: $id}, _set: {status: COMPLETED}) {
            id
            status
        }
    }
`

export const CANCEL_ORDER = gql`
    mutation CancelOrder($id: uuid!) {
        update_order_by_pk(pk_columns: {id: $id}, _set: {status: CANCELED}) {
            id
            status
        }
    }
`

export const UPDATE_ORDER_STATUS_DAMAGE_REPORTED = gql`
    mutation DamageOrder($id: uuid!) {
        update_order_by_pk(pk_columns: {id: $id}, _set: {status: DAMAGE_REPORTED}) {
            id
            status
        }
    }
`
export const CREATE_PAYMENT = gql`
    mutation CreatePayment(
        $orderId: uuid = "",
        $bidId: uuid = "",
        $price: numeric = "",
        $client_id: uuid = "",
        $bid_price: numeric = 10,
        $cargo_price: numeric = "",
        $insurance_price: numeric = "",
        $discount: numeric = 0,
        $method: String = ""
    ){
        insert_payment_one(object: {
            amount: $price,
            method: $method,
            order_id: $orderId,
            bid_id: $bidId,
            status: UNPAID,
            payment_invoice: {data: {
                client_id: $client_id,
                order_id: $orderId,
                sum: $price,
                bid_price: $bid_price,
                cargo_price: $cargo_price,
                insurance_price: $insurance_price,
                discount: $discount
            }
            }}) {
            id
            payment_invoice {
                id
                number
            }
        }
        update_order_by_pk(
            pk_columns: { id: $orderId }
            _set: {
                payment_price: $price
            }
        ) {
            id
            status
        }
    }
`

export const CREATE_PAYMENT_BY_SHIPMENT = gql`
    mutation CreatePaymentByShipment(
        $orderId: uuid = "",
        $shipmentId: String = "",
        $price: numeric = "",
        $client_id: uuid = "",
        $shipment_price: numeric = 10,
        $cargo_price: numeric = "",
        $insurance_price: numeric = "",
        $discount: numeric = 0,
        $method: String = ""
    ){
        insert_payment_one(object: {
            amount: $price,
            method: $method,
            order_id: $orderId,
            shipment_id: $shipmentId,
            status: UNPAID,
            payment_invoice: {data: {
                client_id: $client_id,
                order_id: $orderId,
                sum: $price,
                bid_price: $shipment_price,
                cargo_price: $cargo_price,
                insurance_price: $insurance_price,
                discount: $discount
            }
            }}) {
            id
            payment_invoice {
                id
                number
            }
        }
        update_order_by_pk(
            pk_columns: { id: $orderId }
            _set: {
                payment_price: $price
            }
        ) {
            id
            status
        }
    }
`

export const CONFIRM_ORDER_WITH_TRANSACTION = gql`
    mutation ConfirmOrderWithTransaction(
        $notificationContent: String = ""
        $notificationPriorityStatus: String = ""
        $notificationTitle: String = ""
        $notificationUserId: uuid = ""
        $notificationReceiverUserRole: String = ""
    ) {
        sendNotification(
            content: $notificationContent
            priority_status: $notificationPriorityStatus
            title: $notificationTitle
            user_id: $notificationUserId
            user_role: $notificationReceiverUserRole
        ) {
            response
        }
    }
`

export const GET_CONFIRMED_ORDER = gql`
    query GetConfirmedOrder($id: uuid = "") {
        order_by_pk(id: $id) {
            order_bid {
                bid_carrier {
                    user_id
                }
            }
            shipment_id
            number
        }
    }
`;

/**
 * @type {typeof import('gql/graphql').GetNeopayPaymentLinkDocument}
 */
export const GET_PAYMENT_LINK = gql`
query GetNeopayPaymentLink ($orderId: uuid!, $shipmentId: String, $bidId: uuid, $returnLink: String) {
  getNeopayPaymentLink(orderId: $orderId, shipmentId: $shipmentId, bidId: $bidId, returnLink: $returnLink) {
    url
  }
}
`;
